import React from "react";
import { AppBar, Typography, Toolbar, Button } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import { useOktaAuth } from "@okta/okta-react";

const HeaderComponent = ({ user }) => {
  const theme = useTheme();
  const { authState, oktaAuth } = useOktaAuth();

  const loginWithRedirect = () =>
    oktaAuth.signInWithRedirect({ originalUri: "/" });

  const logOut = () => oktaAuth.signOut();

  const buttonText = authState.isAuthenticated ? "Logout" : "Login";
  const btnLogic = authState.isAuthenticated ? logOut : loginWithRedirect;
  return (
    <>
      <CssBaseline />
      <AppBar
        position="relative"
        elevation={2}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          backgroundColor: "#003A70",
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            sx={{
              flexGrow: 1,
              fontSize: "1.3em",
              fontWeight: 400,
              color: "#ffffff",
              fontFamily: "sans-serif",
              marginLeft: "0.7rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            PFNA Growers’ Potato Yield Optimizer Dashboard
          </Typography>
          <Typography
            component={"h6"}
            sx={{ fontFamily: "Poppins, sans-serif", fontSize: "1em" }}
          >
            {user.currentUserName}{" "}
          </Typography>
          <Button
            color="inherit"
            onClick={btnLogic}
            sx={{
              color: "#ffffff",
              marginLeft: "3px",
              fontFamily: "Poppins, sans-serif",
              fontSize: "1em",
            }}
          >
            {buttonText}
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
};

HeaderComponent.propTypes = {};

export default HeaderComponent;
