import React from "react";
import { Typography, Box, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const FooterComponent = (props) => {
  const theme = useTheme();
  const footerColor = "#003A70";
  return (
    <Box
      sx={{ bgcolor: footerColor, py: "1rem", textAlign: "center" }}
      component="footer"
    >
      <Typography
        sx={{
          color: "white",
          fontSize: "1.3em",
          fontFamily: "sans-serif",
          padding: "10px 0",
        }}
      >
        {props.copyrightText}
      </Typography>
    </Box>
  );
};

FooterComponent.propTypes = {};

export default FooterComponent;
