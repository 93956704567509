import React from "react";
import "../pages/HomePage.css";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

export const PowerBI = ({ PBIaccessToken, getPBIToken }) => {
  let embedURL = process.env.REACT_APP_EMBED_URL;
  let reportId = process.env.REACT_APP_REPORT_ID;
  return (
    <PowerBIEmbed
      cssClassName={"reportClass"}
      embedConfig={{
        type: "report", // Supported types: report, dashboard, tile, visual and qna
        id: reportId,
        embedUrl: embedURL,
        accessToken: PBIaccessToken,
        tokenType: models.TokenType.Embed,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: true,
          background: 0,
          layoutType: models.LayoutType.Custom,
          customLayout: {
            displayOption: models.DisplayOption.FitToWidth,
          },
        },
      }}
      eventHandlers={
        new Map([
          [
            "loaded",
            function () {
              console.log("Report loaded");
            },
          ],
          [
            "rendered",
            function () {
              console.log("Report rendered");
              window.scroll({
                top: 120,
                left: 0,
                behavior: "smooth",
              });
            },
          ],
          [
            "error",
            function (event) {
              console.log(event.detail.message);
              if (event.detail.message == "TokenExpired") {
                getPBIToken();
              }
            },
          ],
        ])
      }
      getEmbeddedComponent={(embeddedReport) => {
        window.Report = embeddedReport;
      }}
    />
  );
};
