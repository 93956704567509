import { useEffect } from "react";

function NotFound(props) {
  useEffect(() => {
    props.history.push("/");
  }, []);
  return <></>;
}

export default NotFound;
