import { Typography, Box, Container, AlertTitle, Alert } from "@mui/material";
import React, { useEffect } from "react";
import HeaderComponent from "../layouts/header/Header.component";
import FooterComponent from "../layouts/footer/Footer.component";
import axios from "axios";
import { PowerBI } from "../components/PowerBI";
import { useOktaAuth } from "@okta/okta-react";
import { jwtDecode } from "jwt-decode";
import "./HomePage.css";

function ShowDashboard({ PBIaccessToken, getPBIToken }) {
  return (
    <>
      {PBIaccessToken != "" ? (
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 4,
            pb: 6,
          }}
        >
          <Container maxWidth="xl">
            <PowerBI
              PBIaccessToken={PBIaccessToken}
              getPBIToken={getPBIToken}
            />
          </Container>
        </Box>
      ) : (
        <div className="pageCenter">
          <Typography className="textCenter">Loading dashboard...</Typography>
        </div>
      )}
    </>
  );
}

function PBINoAccess() {
  return (
    <div className="pageCenter">
      <Alert severity="warning">
        <AlertTitle>Access Denied</AlertTitle>
        You don’t have permissions to access the Grower's Dashboard <br />
        Please submit a{" "}
        <a target="_blank" href="https://myidm.mypepsico.com">
          myIDM
        </a>{" "}
        request to request grower role permission to access this site
      </Alert>
    </div>
  );
}

const HomePage = () => {
  const { oktaAuth } = useOktaAuth();

  const [PBIaccessToken, setPBIaccessToken] = React.useState("");
  const [PBIDashboardAccess, setPBIDashboardAccess] = React.useState(false);
  const [user, setUser] = React.useState({
    currentUserName: "",
    currentUserEmail: "",
    token: "",
  });
  useEffect(() => {
    const idToken = JSON.parse(localStorage.getItem("okta-token-storage"));
    const decoded = jwtDecode(idToken.accessToken.accessToken);
    if (
      decoded.hasOwnProperty(process.env.REACT_APP_PBI_ACCESS_ATTRIBUTE) &&
      decoded.pepappgrowershubstatus == "Active"
    ) {
      setPBIDashboardAccess(true);
    }
    setUser({
      currentUserEmail: idToken.idToken.claims.email,
      currentUserName: idToken.idToken.claims.name,
      token: idToken.accessToken.accessToken,
    });
  }, []);

  const checkTokenExpired = () => {
    let tokenExpirationTime = new Date(PBIaccessToken).getTime();
    const currentTime = new Date().getTime();
    if (currentTime >= tokenExpirationTime) {
      // Token has expired
      getPBIToken();
    }
  };

  useEffect(() => {
    const interval = setInterval(checkTokenExpired, 58 * 60 * 1000); // 58 minutes
    return () => clearInterval(interval);
  }, [new Date(PBIaccessToken).getTime()]);

  const getPBIToken = () => {
    axios
      .get(process.env.REACT_APP_API, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then(function (response) {
        setPBIaccessToken(response.data.data.token);
        response.data.data.token &&
          window.scroll({
            top: 350,
            left: 0,
            behavior: "smooth",
          });
      })
      .catch(function (error) {
        // console.log(error.response.status);
        if (error.response.status == 401) {
          oktaAuth.signOut();
        }
        console.log(error);
      });
  };

  useEffect(() => {
    if (user.token != "" && PBIDashboardAccess) {
      getPBIToken();
    }
  }, [user.token && PBIDashboardAccess]);
  return (
    <>
      <HeaderComponent user={user} />
      {user.token && (
        <>
          {" "}
          {PBIDashboardAccess ? (
            <ShowDashboard
              PBIaccessToken={PBIaccessToken}
              getPBIToken={getPBIToken}
            />
          ) : (
            <PBINoAccess />
          )}
        </>
      )}

      <FooterComponent
        copyrightText={`© ${new Date().getFullYear()} COPYRIGHT, PEPSICO`}
      />
    </>
  );
};

HomePage.propTypes = {};

export default HomePage;
