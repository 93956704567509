import React from "react";
import HomePage from "./pages/Home.page";
import { Route, withRouter } from "react-router-dom";
import { LoginCallback, Security, SecureRoute } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { useEffect } from "react";
import NotFound from "./pages/NotFound.page";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CALLBACK_PATH = process.env.REACT_APP_CALLBACK_PATH;
const ISSUER_DOMAIN = process.env.REACT_APP_ISSUER_DOMAIN;
const REDIRECT_URI = `${window.location.protocol}//${window.location.host}/${CALLBACK_PATH}`;
const SCOPES = process.env.REACT_APP_SCOPES;
const ISSUER = `${ISSUER_DOMAIN}${process.env.REACT_APP_ISSUER}`;

if (!SCOPES || !CLIENT_ID || !CALLBACK_PATH || !ISSUER) {
  throw new Error("All environmental variables must be set");
}

const config = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES.split(/\s+/),
};

const oktaAuth = new OktaAuth(config);

function App(props) {
  const history = props.history;

  useEffect(() => {
    const onTokenExpired = () => {
      oktaAuth.signOut();
    };
    oktaAuth.tokenManager.on("expired", onTokenExpired);
    return () => {
      oktaAuth.tokenManager.off("expired", onTokenExpired);
    };
  }, [oktaAuth]);

  const restoreOriginalUri = React.useCallback(
    async (_oktaAuth, originalUri) => {
      history.replace(
        toRelativeUrl(originalUri || "/", window.location.origin)
      );
    },
    [history]
  );

  return (
    <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
      <Route path="/login/callback" component={LoginCallback} />
      <SecureRoute path="/" exact={true} component={HomePage} />
      <Route path="/healthcheck">
        <h3>Hey There!!! The App is Healthy</h3>
      </Route>
      <SecureRoute path="*" component={NotFound} />
    </Security>
  );
}

const AppWithRouterAccess = withRouter(App);
export default AppWithRouterAccess;
